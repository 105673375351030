<template>
  <b-card>
    <client-form v-if="client" :initial-client="client" ></client-form>
  </b-card>
</template>

<script>
import ClientForm from '@/components/ClientForm'
export default {
  components : {ClientForm},
  name: "ClientsEdit.vue",
  data(){
    return {
      client : false
    }
  },
  async mounted() {
    this.client = await this.$store.dispatch('clientsModule/fetchClient', {
      client_id : this.$route.params.id
    })
  }
}
</script>

<style scoped>

</style>